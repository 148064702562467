@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700&display=swap);
@keyframes rainbow-color-cycle {
  0%,
  to {
    background-color: #c34e4e;
  }
  14% {
    background-color: #cd9f4b;
  }
  28% {
    background-color: #b3b34a;
  }
  42% {
    background-color: #5bb05b;
  }
  57% {
    background-color: #6c6ce6;
  }
  71% {
    background-color: #b071dd;
  }
  85% {
    background-color: violet;
  }
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scaleUp {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    transform: rotate(-90deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes rotateInRev {
  0% {
    transform: rotate(90deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@font-face {
  font-family: "Apple Garamond";
  src: url(./assets/AppleGaramond-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Dolly Dots";
  src: url(./assets/dollydots.ttf) format("truetype");
}
* {
  padding: 0;
  margin: 0;
}
:root {
  --background-color: #ebebeb;
  --primary: black;
  --primary-inverted: white;
  --muted: rgba(0, 0, 0, 0.1);
  --font-color-primary: black;
  --border-color: #a4a4a4;
  --rainbow-color-start: #dc4747;
  --rainbow-color-second: #00daa6;
  --rainbow-color-third: #4b0082;
  --rainbow-color-fourth: #069a1e;
  --rainbow-color-fifth: #6f00ff;
  --font-color-eyebrow: #8c8c8c;
  --font-color-project: #333;
  --slash-color: rgba(0, 0, 0, 0.05);
  --swish: white;
  --gradient-start: #8c8c8c;
  --gradient-end: #ebebeb;
}
.dark-theme {
  --background-color: #181b1a;
  --primary: white;
  --primary-inverted: black;
  --muted: rgba(255, 255, 255, 0.1);
  --font-color-primary: white;
  --border-color: #5d5f5f;
  --rainbow-color-start: #dc4747;
  --rainbow-color-second: #4b0082;
  --rainbow-color-third: #00daa6;
  --rainbow-color-fourth: #069a1e;
  --rainbow-color-fifth: #6f00ff;
  --font-color-eyebrow: #8c8c8c;
  --font-color-project: #ffffffcc;
  --slash-color: rgba(0, 0, 0, 0.2);
  --swish: white;
  --gradient-start: #bababa;
  --gradient-end: #282828;
}
.App,
body {
  overflow-x: hidden;
}
body {
  background-color: var(--background-color);
  font-family: "JetBrains Mono", monospace;
  font-size: 12px;
}
.App {
  display: grid;
  row-gap: 300px;
  padding-left: 32px;
}
.App-header,
.App-marquee p {
  width: 100%;
  text-align: center;
}
.App-header,
.Diagonal-lines {
  display: flex;
  align-items: center;
}
.App-header {
  position: absolute;
  top: 142px;
  min-height: 300px;
  font-size: calc(10px + 2vmin);
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
}
.Diagonal-lines {
  position: fixed;
  top: 0;
  left: 0;
  height: 42px;
  padding: 0 1px;
  gap: -36px;
  overflow: hidden;
  margin-left: -50px;
  z-index: 9999;
}
.slash {
  display: inline-flex;
  width: 16px;
  height: 90px;
  background-color: var(--slash-color);
  transform: rotate(32deg);
  margin: 0 6px;
}
.theme-toggle-button {
  position: absolute;
  top: 52px;
  right: 0;
  background: 0 0;
  border: 0;
  margin-right: 24px;
}
.App-marquee {
  white-space: nowrap;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  background-color: #00daa6;
  color: #000;
  padding: 8px 0;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  animation: rainbow-color-cycle 10s linear infinite;
  z-index: 10000;
}
.App-marquee p {
  font-family: "Jetbrains Mono", monospace;
  font-size: 12px;
  font-weight: 700;
  height: 100%;
  margin: 0;
  transform: translateX(100%);
  animation: marquee 20s linear infinite;
  animation-delay: -5s;
}
.experience {
  position: relative;
  top: 300px;
  margin: 100px 0 42px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 64px 82px;
}
.experience > div {
  flex: 1 1 calc(33.333% - 52px);
  min-width: 250px;
  max-width: 343px;
}
@media (min-width: 1200px) {
  .experience {
    justify-content: start;
    gap: 64px 52px;
  }
}
@media (min-width: 1300px) {
  .experience {
    justify-content: start;
    gap: 64px 106px;
  }
}
@media (min-width: 1400px) {
  .experience {
    justify-content: start;
    gap: 64px 126px;
  }
  .experience > div {
    max-width: 353px;
  }
}
@media (min-width: 1500px) {
  .experience {
    justify-content: start;
    gap: 64px 176px;
  }
  .experience > div {
    max-width: 353px;
  }
}
@media (min-width: 1600px) {
  .experience {
    justify-content: start;
    gap: 64px 224px;
  }
  .experience > div {
    max-width: 353px;
  }
}
.Resume-section {
  max-width: 400px;
}
.Resume-years {
  color: var(--font-color-eyebrow);
}
.Resume-title {
  font-family: "Apple Garamond", serif;
  font-size: 24px;
  font-weight: 700;
  color: var(--font-color-project);
}
.Resume-table {
  margin-top: 16px;
  margin-bottom: 16px;
}
.Resume-description {
  color: var(--font-color-eyebrow);
  line-height: 1.5em;
}
.split,
.table-svg rect {
  stroke: var(--border-color);
}
.table-svg rect {
  fill: none;
}
.table-svg path {
  fill: var(--font-color-primary);
}
.split {
  fill: none !important;
}
.cross {
  fill: var(--border-color) !important;
}
.selected-work {
  padding-top: 16px;
}
.Projects {
  display: flex;
  flex-direction: column;
  margin: 64px auto;
  font-size: 12px;
  font-weight: light;
  overflow: hidden;
}
.Project::-webkit-scrollbar {
  display: none;
}
.Project-title {
  font-size: 52px;
  font-weight: 100;
  font-family: "Dolly Dots";
  text-transform: uppercase;
  margin: 0;
  color: var(--font-color-project);
  max-width: 200px;
  white-space: normal;
  word-wrap: break-word;
}
.Project-description,
.eyebrow {
  color: var(--font-color-eyebrow);
}
.eyebrow {
  display: none;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
.Project-description {
  width: 16rem;
  margin-right: 32px;
  margin-top: 24px;
  text-transform: lowercase;
  line-height: 1.5em;
}
.Project-links {
  padding-left: 12px;
  margin-top: 12px;
}
.Project-links a,
a:visited {
  color: var(--primary);
  text-decoration: none;
  text-transform: lowercase;
}
.Project-links a:hover {
  color: var(--font-color-eyebrow);
}
.Project-links li {
  list-style-type: circle;
  color: var(--primary);
  margin-bottom: 4px;
}
.Project {
  display: flex;
  margin-bottom: 120px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.Project-images {
  display: inline-flex;
  overflow-x: scroll;
  width: fit-content;
}
.Project-images img {
  width: 70%;
  height: auto;
  max-height: 600px;
  margin-right: 20px;
  border: 0.5px dashed var(--border-color);
  object-fit: contain;
}
.circle-text {
  animation: rotate-clockwise 20s linear infinite;
  display: inline;
  position: relative;
  left: 167px;
  top: -20px;
  opacity: 0.5;
  font-weight: 400;
  margin-bottom: -100px;
}
.circle-text text {
  fill: var(--rainbow-color-third);
}
.stack {
  width: 100vw;
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-top: -52px;
}
.stack svg {
  scale: 0.8;
}
.stack svg path {
  fill: var(--primary);
}
.App-footer,
.App-footer a {
  color: var(--font-color-project);
}
.App-footer {
  background-color: var(--primary-inverted);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: fit-content;
  line-height: 18px;
  margin-top: 120px;
}
.App-footer a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
}
.App-footer .ext-button {
  padding: 16px 64px;
  text-align: center;
  border-radius: 3px;
  background-color: var(--muted);
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.App-footer .ext-button:hover {
  background-color: var(--font-color-eyebrow);
}
.App-footer p {
  margin-bottom: 0;
  margin-top: 0;
}
.App-footer .Project-title {
  margin-top: 4px;
}
.conclusion {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 52px;
  margin: 0 auto;
}
svg .cross-hatch {
  stroke: var(--border-color);
}
.version-container {
  width: 100%;
  margin: 20px auto;
  position: relative;
  bottom: 12px;
}
.version-footer {
  width: 100%;
  text-align: center;
  color: var(--border-color);
  opacity: 0.5;
}
.rainbow-effect-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 10000;
}
.rainbow-effect-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(30px);
  filter: contrast(150%) brightness(110%);
  background: url(./assets/noise.svg)
    linear-gradient(90deg, #dc4747, #00daa6, indigo, #069a1e, #6f00ff);
  mix-blend-mode: color-dodge;
  background-blend-mode: color-burn;
  opacity: var(--rainbow-opacity, 0);
  pointer-events: none;
  z-index: 10000;
  transition: opacity 0.2s ease-in-out;
}
#ring,
#star-big,
#star-small,
.wolf-logo {
  opacity: 0;
}
#wolf-fill {
  fill: linear-gradient(
    to bottom,
    #bababa 31.138%,
    rgba(186, 186, 186, 0) 100%
  );
  stroke: var(--background-color);
  stroke-width: 2px;
  stroke-linejoin: round;
}
.wolf-logo {
  position: relative;
  top: -136px;
  animation: fadeInUp 1s ease forwards;
  animation-delay: 1.9s;
}
.svg-loading circle {
  fill: var(--background-color);
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  transition: fill 1s;
  scale: 210%;
}
.svg-loading {
  scale: 153%;
  width: 100vw;
  height: 100px;
  margin-top: 100px;
}
#stars path {
  fill: var(--font-color-eyebrow);
}
#star-big,
#star-small {
  animation: rotateIn 1.1s ease forwards;
  transform-origin: center center;
  animation-delay: 1.4s;
}
#star-small {
  animation: rotateInRev 1s ease forwards;
  animation-delay: 1.5s;
}
#ring {
  fill: var(--font-color-eyebrow);
  animation: fadeInUp 1.5s ease forwards;
  animation-delay: 0.1s;
}
#left-leaves path:nth-child(n),
#right-leaves path:nth-child(n) {
  fill: var(--font-color-eyebrow);
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
}
#left-leaves path:nth-child(1),
#right-leaves path:nth-child(1) {
  animation-delay: 0.1s;
}
#left-leaves path:nth-child(2),
#right-leaves path:nth-child(2) {
  animation-delay: 0.2s;
}
#left-leaves path:nth-child(3),
#right-leaves path:nth-child(3) {
  animation-delay: 0.3s;
}
#left-leaves path:nth-child(4),
#right-leaves path:nth-child(4) {
  animation-delay: 0.4s;
}
#left-leaves path:nth-child(5),
#right-leaves path:nth-child(5) {
  animation-delay: 0.5s;
}
#left-leaves path:nth-child(6),
#right-leaves path:nth-child(6) {
  animation-delay: 0.6s;
}
#left-leaves path:nth-child(7),
#right-leaves path:nth-child(7) {
  animation-delay: 0.7s;
}
#left-leaves path:nth-child(8),
#right-leaves path:nth-child(8) {
  animation-delay: 0.8s;
}
#left-leaves path:nth-child(9),
#right-leaves path:nth-child(9) {
  animation-delay: 0.9s;
}
#left-leaves path:nth-child(10),
#right-leaves path:nth-child(10) {
  animation-delay: 1s;
}
#left-leaves path:nth-child(11),
#right-leaves path:nth-child(11) {
  animation-delay: 1.1s;
}
#left-leaves path:nth-child(12),
#right-leaves path:nth-child(12) {
  animation-delay: 1.2s;
}
#left-leaves path:nth-child(13),
#right-leaves path:nth-child(13) {
  animation-delay: 1.3s;
}
#left-leaves path:nth-child(14),
#right-leaves path:nth-child(14) {
  animation-delay: 1.4s;
}
#left-leaves path:nth-child(15),
#right-leaves path:nth-child(15) {
  animation-delay: 1.5s;
}
#left-leaves path:nth-child(16),
#right-leaves path:nth-child(16) {
  animation-delay: 1.6s;
}
#left-leaves path:nth-child(17),
#right-leaves path:nth-child(17) {
  animation-delay: 1.7s;
}
#left-leaves path:nth-child(18),
#right-leaves path:nth-child(18) {
  animation-delay: 0.1s;
}
#left-leaves path:nth-child(19),
#right-leaves path:nth-child(19) {
  animation-delay: 0.2s;
}
#left-leaves path:nth-child(20),
#right-leaves path:nth-child(20) {
  animation-delay: 0.3s;
}
#left-leaves path:nth-child(21),
#left-leaves path:nth-child(22),
#right-leaves path:nth-child(21),
#right-leaves path:nth-child(22) {
  animation-delay: 0.4s;
}
#left-leaves path:nth-child(23),
#right-leaves path:nth-child(23) {
  animation-delay: 0.6s;
}
#left-leaves path:nth-child(24),
#right-leaves path:nth-child(24) {
  animation-delay: 0.7s;
}
#left-leaves path:nth-child(25),
#right-leaves path:nth-child(25) {
  animation-delay: 0.8s;
}
#left-leaves path:nth-child(26),
#right-leaves path:nth-child(26) {
  animation-delay: 0.9s;
}
#left-leaves path:nth-child(27),
#right-leaves path:nth-child(27) {
  animation-delay: 1s;
}
#left-leaves path:nth-child(28),
#right-leaves path:nth-child(28) {
  animation-delay: 1.1s;
}
#left-leaves path:nth-child(29),
#right-leaves path:nth-child(29) {
  animation-delay: 1.2s;
}
#left-leaves path:nth-child(30),
#right-leaves path:nth-child(30) {
  animation-delay: 1.3s;
}
#left-leaves path:nth-child(31),
#right-leaves path:nth-child(31) {
  animation-delay: 1.4s;
}
#left-leaves path:nth-child(32),
#right-leaves path:nth-child(32) {
  animation-delay: 1.5s;
}
#left-leaves path:nth-child(33),
#right-leaves path:nth-child(33) {
  animation-delay: 1.6s;
}
#left-leaves path:nth-child(34),
#right-leaves path:nth-child(34) {
  animation-delay: 1.7s;
}
#left-leaves path:nth-child(35),
#right-leaves path:nth-child(35) {
  animation-delay: 1.8s;
}
#left-leaves path:nth-child(36),
#right-leaves path:nth-child(36) {
  animation-delay: 1.9s;
}
@media (max-width: 768px) {
  #wolf-fill {
    stroke-width: 3px;
  }
  .App-header svg {
    scale: 1.3;
  }
  .App-footer {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: -32px;
  }
  .App-footer a {
    display: inline-block;
    width: 100vw;
    margin: 0 20px;
  }
  .ext-button {
    margin: 0 20px;
  }
  .svg-loading {
    transform: scale(0.586);
    transform-origin: bottom center;
    margin-top: 0;
  }
  .conclusion {
    margin: 0 auto 20px;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 100vw;
  }
  .App {
    margin: 0 auto;
    padding: 0;
  }
  .App-header {
    min-height: 120px;
    font-size: calc(8px + 2vmin);
    padding: 0;
  }
  .Resume-section {
    padding: 0;
  }
  .Resume-description {
    width: 335px;
  }
  .Resume-table svg {
    scale: 100%;
  }
  .Header-title {
    font-size: 2.5rem;
  }
  .Header-subtitle {
    font-size: 0.7rem;
    max-width: 180px;
  }
  .Skillset-column {
    flex-basis: 100px;
  }
  .App-marquee {
    border: 0;
  }
  .App-marquee p {
    font-size: 14px;
  }
  .selected-work {
    width: 343px;
    margin: 0 auto;
  }
  .Projects {
    margin-left: 0;
    overflow: visible;
  }
  .Project {
    flex-direction: column;
  }
  .Project-title {
    max-width: none;
  }
  .Project-description {
    width: auto;
    margin-right: 0;
    margin-bottom: 24px;
  }
  .Project-images {
    min-height: 300px;
  }
  .Project-images img {
    width: 72%;
  }
  .circle-text {
    left: 222px;
    top: -30px;
  }
  .experience {
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin: 100px 0 0;
  }
  .experience > div {
    flex: 1 1 100%;
  }
  .version-container {
    position: relative;
  }
  .version-footer {
    width: 310px;
    margin: 20px auto;
    font-size: 10px;
  }
}
